import React, { useMemo, useState } from "react";
import { useApiUrl, useCustom, useGetIdentity, useNavigation, useTranslate } from "@refinedev/core";
import dayjs from "dayjs";
import { Card, Col, Row, Badge, Button, Typography } from 'antd';
import { BoxPlotOutlined, UserAddOutlined, AlertOutlined, GiftOutlined, ClockCircleOutlined, FundProjectionScreenOutlined } from '@ant-design/icons';
import UsersTimeGraph from "../../components/UsersTimeGraph";
import DonationTimeGraph from "../../components/DonationTimeGraph";
import ReservationsTimeGraph from "../../components/ReservationsTimeGraph";
import InventoryAvailabilityGraph from "../../components/InventoryAvailabilityGraph";
import { Title } from "@refinedev/antd";

export const DashboardPage: React.FC = () => {
	const t = useTranslate();

	const { list, show } = useNavigation();
	const { data: identity } = useGetIdentity<any>();

	const { data: KpisData } = useCustom<{
		usersThruTime: any[];
		inventoryItemsToReview: number;
		documentsToReview: number;
		donationsToReview: number;
		donationsThruTime: any[];
		donationsToPickup: number;
		reservationsThruTime: any[];
		inventoryItemsAvailableThruTime: any[];
		totalPotsBalance: number;
		totalOffersClaimed: number;
		totalDonations: number;
		totalReservations: number;
	}>({
		url: `${import.meta.env.VITE_API_URL}/kpis`,
		method: "get",
	});
	// Action required
	const inventoryItemsToReview = KpisData?.data?.inventoryItemsToReview
	const donationsToReview = KpisData?.data?.donationsToReview
	const donationsToPickup = KpisData?.data?.donationsToPickup
	const documentsToReview = KpisData?.data?.documentsToReview

	// Information
	const usersThruTime = KpisData?.data?.usersThruTime
	const donationsThroughTime = KpisData?.data?.donationsThruTime
	const reservationsThruTime = KpisData?.data?.reservationsThruTime
	const inventoryItemsAvailableThruTime = KpisData?.data?.inventoryItemsAvailableThruTime
	const totalPotsBalance = KpisData?.data?.totalPotsBalance
	const totalOffersClaimed = KpisData?.data?.totalOffersClaimed
	const totalDonations = KpisData?.data?.totalDonations
	const totalReservations = KpisData?.data?.totalReservations

	return (
		<Card title={t('dashboard.title')} style={{ marginBottom: 20 }} >
			<Typography.Title level={4}>{t('dashboard.welcome')}</Typography.Title>
			<Col xs={24} sm={12} md={8} lg={6}>
				<Card
					title={t('dashboard.user')}
					type="inner"
					className="mb-8"
				>
					<div style={{ fontSize: '28px' }}>
						<p className="text-center text-sm">{identity?.firstName} {identity?.lastName}</p>
						<p className="text-center text-sm">{identity?.email}</p>
						<p className="text-center text-sm">{identity?.phoneNumber}</p>
						<p className="text-center text-sm">{identity?.role}</p>

					</div>
				</Card>
			</Col>

			<Row gutter={[16, 16]}>
				<Col xs={24} sm={12} md={8} lg={6}>
					<Badge.Ribbon text={t('dashboard:urgent')} color="#e34958">
						<Card
							title={t('dashboard.inventoryItemsToReview')}
							type="inner"
						// className="row"
						>
							<div className="flex flex-row items-center justify-around" style={{ fontSize: '28px' }}>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '28px' }}>
									<AlertOutlined style={{ color: '#EDA0D8', marginRight: '10px' }} />
									{inventoryItemsToReview}
								</div>
								<Button type="default" onClick={() => list('inventory')}>{t('dashboard:review')}</Button>
							</div>
						</Card>
					</Badge.Ribbon>
				</Col>
				<Col xs={24} sm={12} md={8} lg={6}>
					<Badge.Ribbon text={t('dashboard:urgent')} color="#e34958">
						<Card
							title={t('dashboard.donationsToReview')}
							type="inner"
							className="flex-1"
						>
							<div className="flex flex-row items-center justify-around" style={{ fontSize: '28px' }}>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '28px' }}>
									<AlertOutlined style={{ color: '#EDA0D8', marginRight: '10px' }} />
									{donationsToReview}
								</div>
								<Button type="default" onClick={() => list('donation')}>{t('dashboard:review')}</Button>
							</div>
						</Card>
					</Badge.Ribbon>
				</Col>
				<Col xs={24} sm={12} md={8} lg={6}>
					<Badge.Ribbon text={t('dashboard:urgent')} color="#e34958">
						<Card
							title={t('dashboard.donationsToPickup')}
							type="inner"
							className="flex-1"
						>
							<div className="flex flex-row items-center justify-around" style={{ fontSize: '28px' }}>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '28px' }}>
									<AlertOutlined style={{ color: '#EDA0D8', marginRight: '10px' }} />
									{donationsToPickup}
								</div>
								<Button type="default" onClick={() => list('donation')}>{t('dashboard:review')}</Button>
							</div>
						</Card>
					</Badge.Ribbon>
				</Col>
				<Col xs={24} sm={12} md={8} lg={6}>
					<Badge.Ribbon text={t('dashboard:urgent')} color="#e34958">
						<Card
							title={t('dashboard.documentsToReview')}
							type="inner"
							className="flex-1"
						>
							<div className="flex flex-row items-center justify-around" style={{ fontSize: '28px' }}>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '28px' }}>
									<AlertOutlined style={{ color: '#EDA0D8', marginRight: '10px' }} />
									{documentsToReview}
								</div>
								<Button type="default" onClick={() => list('documents')}>{t('dashboard:review')}</Button>
							</div>
						</Card>
					</Badge.Ribbon>
				</Col>
			</Row>
			<Typography.Title level={4} className="mt-8">{t('dashboard.statistics')}</Typography.Title>
			<Row gutter={[16, 16]} style={{ marginTop: 20 }}>
				<Col xs={24} sm={12} md={8} lg={6}>
					<Card
						title={t('dashboard.totalDonations')}
						extra={<GiftOutlined />}
						type="inner"
						style={{ flex: 1 }}
					>
						<Typography.Title level={2} className="text-center">{totalDonations}</Typography.Title>
					</Card>
				</Col>
				<Col xs={24} sm={12} md={8} lg={6}>
					<Card
						title={t('dashboard.totalReservations')}
						extra={<ClockCircleOutlined />}
						type="inner"
						style={{ flex: 1 }}
					>
						<Typography.Title level={2} className="text-center">{totalReservations}</Typography.Title>
					</Card>
				</Col>
				<Col xs={24} sm={12} md={8} lg={6}>
					<Card
						title={t('dashboard.totalPotsBalance')}
						extra={<FundProjectionScreenOutlined />}
						type="inner"
						style={{ flex: 1 }}
					>
						<Typography.Title level={2} className="text-center">{totalPotsBalance}</Typography.Title>
					</Card>
				</Col>
				<Col xs={24} sm={12} md={8} lg={6}>
					<Card
						title={t('dashboard.totalOffersClaimed')}
						extra={<BoxPlotOutlined />}
						type="inner"
						style={{ flex: 1 }}
					>
						<Typography.Title level={2} className="text-center">{totalOffersClaimed}</Typography.Title>
					</Card>
				</Col>
			</Row>

			<Row gutter={[16, 16]} style={{ marginTop: 20 }}>
				<Col xs={24} md={12}>
					<Card
						title={t('dashboard.usersThruTime')}
						extra={<UserAddOutlined />}
						type="inner"
						className="flex-1"
					>
						<UsersTimeGraph data={usersThruTime} />
					</Card>
				</Col>
				<Col xs={24} md={12}>
					<Card
						title={t('dashboard.donationsThruTime')}
						extra={<GiftOutlined />}
						type="inner"
						className="flex-1"
					>
						<DonationTimeGraph data={donationsThroughTime} />
					</Card>
				</Col>
			</Row >
			<Row gutter={[16, 16]} style={{ marginTop: 20 }}>
				<Col xs={24} md={12}>
					<Card
						title={t('dashboard.reservationsThruTime')}
						extra={<ClockCircleOutlined />}
						type="inner"
					// className="flex-1"
					>
						<ReservationsTimeGraph data={reservationsThruTime} />
					</Card>
				</Col>
				<Col xs={24} md={12}>
					<Card
						title={t('dashboard.inventoryItemsAvailableThruTime')}
						extra={<FundProjectionScreenOutlined />}
						type="inner"
					// className="flex-1"
					>
						<InventoryAvailabilityGraph data={inventoryItemsAvailableThruTime} />
					</Card>
				</Col>
			</Row>
		</Card >
	);
};
